let Singleton = (function(){

	let instance = undefined;

	class Observer{
		constructor(){
			this.subscribers = [];
		}
		/**
		 * ADD ON EVENT SUBSCRIBER
		 */
		addSubscriber(new_instance){
			this.subscribers.push(new_instance);
		};
		/**
		 * HANDLE EVENTS
		 */
		emitEvent(data){
			this.subscribers.forEach( subscriber => subscriber.notify(data) );
		};
	};

	return {
		getInstance: function(){
			if(!instance) instance = new Observer();
			return instance;
		}
	}
})();
export default Singleton.getInstance();
