import Observer from './observer.js';

let Singleton = (function(){

	let clickEvent = 'ontouchstart' in document.documentElement ? 'touchend' : 'click';
	let instance = undefined;

	class Driver{
		
		constructor(){
			let _this = this;
			/**
			 * SCROLL ON MANUAL DRAG (CRM PIPELINE)
			 */
			$(document).on('mousedown touchstart', '.stage-deals', function(event){ 
				//event.stopPropagation();
				//event.preventDefault();
				let scroll_block_wrapper = $('#pipelines'); 
				let scroll_block = $('.pipeline');
				let scroll_left = scroll_block_wrapper.scrollLeft();
				let start_x = event.pageX;
				let scroll = true;
				let scroll_speed = 1;
				$(document).on('mousemove touchmove', scroll_block, function(e){
					if(scroll){
						e.preventDefault();
						e.stopPropagation();
						const x = e.pageX;
						const step = (x - start_x) * scroll_speed;
						scroll_block_wrapper.scrollLeft(scroll_left - step);
					}
				});

				$(document).on('mouseup touchend', function(){
					scroll = false;
				})
			});
			/**
			 * MINIMIZE MAXIMIZE PANEL
			 */
			$(document).on(clickEvent, '.panel-minimized', function(){
				let id = $(this).data('panel-id');
				$('.panel.popup.onfocus').removeClass('onfocus');
				$(`.panel.popup#${id}`).addClass('show').addClass('onfocus');
				$(this).remove();
			});
			/**
			 * CATCH APP-VIEW CLICK (CLICK OUTSIDE THE POPUPS)
			 */
			$(document).on(clickEvent, '#main-views', function(e){
				$('.panel.popup.show').each(function(){
					let id = $(this).prop('id');
					let title = $(this).find('.panel-title').text();
					$('#minimized-panels-block').append(`<div class="panel-minimized ellipsis" data-panel-id="${id}">${title}</div>`);
					$(this).removeClass('show');					
				})
			});
			/**
			 * TABS
			 * Element: <button class="tab" data-event="show-panel"></button>
			 */
			$(document).on(clickEvent, '.tab', function(){
				if($(this).hasClass('active')) return;
				let index = $(this).index();
				let parent = $(this).closest('.tabs-block');
				parent.find('.tab.active').removeClass('active');
				parent.find('.tab-view.show').removeClass('show');
				$(this).addClass('active');
				parent.find('.tab-view').eq(index).addClass('show');
			});
			/**
			 * OVERLAY
			 */
			$(document).on(clickEvent, '.overlay', function(){
				$(this).hide();
				$('.panel').each(function(){
					$(this).removeClass('show');
					setTimeout(() => $(this).remove(), 700);
				})
			});		
			/**
			 * DRAG POPUP PANELS
			 */
			$(document).on('mousedown touchstart', '.panel.popup', function (element) {
				//element.stopPropagation();
				//element.preventDefault();
				let panel = $(this).closest('.panel');
				if(panel.hasClass('fullscreen')) return;
				let classes = panel.attr('class').split(/\s+/); // Read classes of panel
				if(classes.indexOf('popup') == -1) return; // If panel is not popup - stop
				let start_x = element.clientX;
				let start_y = element.clientY;
				let drag = true;
				$(document).on('mousemove', function (e) {
					if (drag === false) return;
					e.preventDefault();
					e.stopPropagation();
					let moveX = start_x - e.pageX;
					let moveY = start_y - e.pageY;
					let pos_y = (panel.offset().top - moveY);
					let pos_x = (panel.offset().left - moveX);
					start_x = e.pageX;
					start_y = e.pageY;
					panel.offset({ top: pos_y, left: pos_x })
				});
				$(document).on('mouseup', () => drag = false);
			});
			/**
			 * 	CHANGE POPUP PANEL Z-INDEX
			 */
			$(document).on(clickEvent, '.popup.show', function(){
				if($(this).hasClass('onfocus')) return;
				$('.popup.onfocus').removeClass('onfocus');
				$(this).addClass('onfocus');
				let z_index = $(this).css('z-index');
				let top_z_index = $('.popup.show').length + 100;
				if(z_index == top_z_index) return;
				$('.popup.show').each(function(){
					let this_z_index = $(this).css('z-index');
					if(this_z_index > z_index) $(this).css('z-index', this_z_index - 1);
				});
				$(this).css('z-index', top_z_index);
			});
			/**
			 * PREVENT BUTTON BUBBLING ON FIELD-BUTTON
			 */
			$(document).on('mousedown touchstart', '.no-propagate', function(e){ 
				return false;
			});

			$(document).on('click touchstart', 'input[type=checkbox], input[type=radio]', function(e){ 
				e.stopPropagation();
			});
			/**
			 * FULLSCREEN PANEL
			 */
			$(document).on('dblclick', '.panel-header', function(){
				let panel = $(this).closest('.panel.popup');
				if(panel.hasClass('resizable')) panel.toggleClass('fullscreen');
			});
			/**
			 * RESIZE
			 */
			$(document).on('mouseenter', '.panel.resizable', function(){
				let panel = $(this);
				let dx = 8;
				let dy = 8;
				$(document).on('mousemove', function(e){
					let x = e.pageX;
					let y = e.pageY;
					let x1 = panel.offset().left;
					let x2 = panel.offset().left + panel.outerWidth();
					let y1 = panel.offset().top;
					let y2 = panel.offset().top + panel.outerHeight(); 
					if((x2 - x) <= dx && (x2 - x) >= -5){
						panel.addClass('resize-right');
					}else{
						panel.removeClass('resize-right');
					}; 
					if((y2 - y) <= dy && (y2 - y) >= 0){
						panel.addClass('resize-down');
					}else{
						panel.removeClass('resize-down');
					}; 
					if((x - x1) <= dx){
						panel.addClass('resize-left');
					}else{
						panel.removeClass('resize-left');
					};
				})
			});

			$(document).on('mousedown', '.resize-right', function(event){
				let resize = true;
				let panel = $(this);
				let top = panel.offset().top;
				let left = panel.offset().left;
				let w = panel.outerWidth(true);
				let x = event.pageX;
				$(document).on('mousemove', function(e){
					if(resize){
						let dx = e.pageX - x;
						panel.css({
							'width': (w + dx) + 'px',
							// 'top': top + 'px',
							// 'left': left  + 'px'
						});						
					}
				});
				$(document).on('mouseup', function(){
					let w = panel.outerWidth(true);
					panel.css('width', Math.ceil(w) + 'px');	
					resize = false;
				});
			});

			$(document).on('mousedown', '.resize-down', function(event){
				let resize = true;
				let panel = $(this);
				let top = panel.offset().top;
				let left = panel.offset().left;
				let h = panel.outerHeight(true);
				let y = event.pageY;
				$(document).on('mousemove', function(e){
					if(resize){
						let dy = e.pageY - y;
						panel.css({
							'height': (h + dy) + 'px',
							// 'top': top + 'px',
							// 'left': left  + 'px'
						});						
					}
				});
				$(document).on('mouseup', function(){
					let h = panel.outerWidth(true);
					panel.css('width', Math.ceil(h) + 'px');	
					resize = false;
				});
			});
			/**
			 * GET APP EVENTS AND REDIRECT TO METHODS
			 */
			function Subscriber() {
				this.notify = obj => {
					switch (obj.event) {
						//case 'hide-panel': obj.target.closest('.panel.popup').toggleClass('fullscreen'); break;
						case 'maximize-panel': _this.maximize(obj); break;
						case 'minimize-panel': _this.minimize(obj); break;
						case 'hide-panel': _this.hidePanel(obj.target); break;
						case 'checkbox-group': _this.checkboxGroup(obj.target); break;
					}
				}
			};
			/**
			 * SUBSCRIBE TO EVENTS
			 */
			Observer.addSubscriber(new Subscriber());
		};
		/**
		 * MAX/MIN PANELS
		 */
		maximize(obj){
			obj.target.closest('.panel.popup').toggleClass('fullscreen')
		};

		minimize(obj){
			let panel = obj.target.closest('.panel');
			let id = panel.prop('id');
			let title = panel.find('.panel-title').text();
			$('#minimized-panels-block').append(`<div class="panel-minimized ellipsis" data-panel-id="${id}">${title}</div>`);
			panel.removeClass('show');
			setTimeout(() => panel.removeClass('onfocus'), 100);
		};
		/**
		 * HANDLE CHECKBOX
		 */
		checkboxGroup(target){
			if(target.prop('checked')){
				target.closest('table').find('input[type=checkbox]').prop('checked', true);
			}else{
				target.closest('table').find('input[type=checkbox]').prop('checked', false);
			};
		};
		/**
		 * PRELOADER
		 */
		preloader(){
			$('#preloader').toggle()
		};
		/**
		 * SWIPE PANEL
		 */
		swipeNext(){
			$('.swipe-block').css({'transform':'translateX(-50%)'});
		};

		swipePrev(){
			$('.swipe-block').css({'transform':'translateX(0)'});
		};

		showPanel(panel_id){
			let panel = $(`#${panel_id}`);
			if(panel.hasClass('popup')){
				setTimeout(() => {
					if(panel.hasClass('center')){
						panel.css({
							'top': '50%',
							'left': '50%',
							'transform': 'translate(-50%,-50%)',
						})
					}else if($('.popup.onfocus').length > 0){
						panel.css({
							'top': $('.popup.onfocus').offset().top + 40 + 'px', 
							'left': $('.popup.onfocus').offset().left - 40 + 'px'
						})				
					}else{
						panel.css({
							'top': '50%',
							'left': '50%',
							'transform': 'translate(-50%,-50%)',
						})
					};
					$('.popup.onfocus').removeClass('onfocus');
					panel.addClass('show').addClass('onfocus').css('z-index', $('.popup.show').length + 100);
				}, 100)
			}else{
				setTimeout(() => panel.addClass('show'), 100);				
			}
		};

		hidePanel(target){
			let panel_id = target.closest('.panel').attr('id');
			let panel = $(`#${panel_id}`);
			if(panel.hasClass('popup')){
				let z_index = panel.css('z-index');
				panel.remove();	
				let top_z_index = $('.popup.show').length + 100;			
				$('.popup.show').each(function(){
					let this_z_index = $(this).css('z-index'); 
					if(this_z_index > z_index) $(this).css('z-index', this_z_index - 1); 
				});
				setTimeout(() => {
					top_z_index = $('.popup.show').length + 100;				
					$('.popup.show').each(function(){
						let this_z_index = $(this).css('z-index'); 
						if(this_z_index == top_z_index) $(this).addClass('onfocus');
					});
				}, 100);
			}else{
				panel.removeClass('show');
				setTimeout(() => panel.remove(), 700);
			}
		};

	};

	return {
		getInstance: function(){
			if(!instance) instance = new Driver();
			return instance;
		}
	};

})();
export default Singleton.getInstance();