import Uuid from './utils/uuid.js';
import Icons from './utils/icons.js';
import Cookies from './utils/cookies.js';
import Observer from './utils/observer.js';
import Driver from './utils/driver.js';
import DateTime from './utils/datetime.js';
//import ClientChat from './utils/client.chat.js';
import ContactsController from './mvc/visitor/contacts/controller.js';
import AuthController from './mvc/visitor/authorize/controller.js';
//import WebchatController from './mvc/visitor/webchat/controller.js';
import './widgets/slider.js';
import './widgets/polyslider.js';
import Gmap from './utils/gmap.js';
/**
 * SET CLICK OR TOUCH EVENT DEPENDS ON DEVICE
 */
window.clickEvent = 'ontouchstart' in document.documentElement ? 'touchend' : 'click';
/**
 * SET GLOBAL VARS
 */
window.DateTime = DateTime;
/**
 * SET GLOBAL OBJECTS
 */
window.Cookies = Cookies;
window.Icons = Icons;
window.ValidateEmail = function (str) {
    return (/^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,16}\.)?[a-z]{2,16}$/i.test(str));
};
/**
 * SET UID
 */
if (!localStorage.getItem('visitor_id')) localStorage.setItem('visitor_id', Uuid.code);
/**
 * CONNECT SOCKET
 * Pass to func uid and client type = visitor
 */
//ClientChat.connect(localStorage.getItem('visitor_id'), 'visitor');
/**
 * SHOW WEBCHAT WIDGET
 */
//WebchatController.createWebchat();
/**
 * PAGE SCROLL NAVIGATION
 */
$(document).on(clickEvent, '.nav-link', function () {
    let id = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(id).offset().top
    }, 1000);
});
/**
 * INIT GMAP
 */
setTimeout(() => Gmap.createMap('address-map', 53.236224, -6.625035), 2000);
/**
 * ALLOW ONLY NUMBERS
 */
$(document).on('input paste', 'input[type=number]', function () {
    let x = $(this).val().replace(/[^0-9.,]/gi, '');
    $(this).val(x);
});
/**
 * ALLOW PHONE NUMBERS
 */
$(document).on('input paste', 'input[type=tel]', function () {
    let x = $(this).val().replace(/[^0-9+]/gi, '');
    $(this).val(x);
});
/**
 * SUBMENU SHOW/HIDE
 */
$(document).on('mouseenter', '.btn-submenu', function () {
    $(this).find('.submenu').addClass('show');
}).on('mouseleave', '.btn-submenu', function () {
    $(this).find('.submenu').removeClass('show');
});
/**
 * HANDLE MESSAGE ONCLICK EVENTS
 */
$(document).on(clickEvent, '.message.button', function () {
    Observer.emitEvent({
        'event': $(this).attr('data-event'),
        'target': $(this)
    });
});
/**
 * HANDLE BUTTON ONCLICK EVENTS
 */
$(document).on(clickEvent, 'button', function () {
    Observer.emitEvent({
        'event': $(this).attr('data-event'),
        'target': $(this)
    });
});
/**
 * START HOMEPAGE VIDEO
 */
if ($('#video').length > 0) {
    //setTimeout(() => document.getElementById('video').play(), 1500);
};
/**
 * GET APP EVENTS AND REDIRECT TO METHODS
 */
function Subscriber() {
    this.notify = obj => {
        switch (obj.event) {
            case 'show-mobile-menu': Driver.slideOnPanel('mobile-menu-panel'); break;
            case 'hide-panel': Driver.hidePanel(obj.target); break;
        }
    }
};
/**
 * SUBSCRIBE TO EVENTS
 */
Observer.addSubscriber(new Subscriber());