class Icons{
	
	constructor(){}
	/**
	 * A
	 */
	arrow_left(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />`+
			`</svg>`
		)
	};
	arrow_right(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />`+
			`</svg>`
		)
	};
	/**
	 * B
	 */
	bell(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M16,17H7V10.5C7,8 9,6 11.5,6C14,6 16,8 16,10.5M18,16V10.5C18,7.43 15.86,4.86 13,4.18V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V4.18C7.13,4.86 5,7.43 5,10.5V16L3,18V19H20V18M11.5,22A2,2 0 0,0 13.5,20H9.5A2,2 0 0,0 11.5,22Z" />`+
			`</svg>`
		)
	};
	/**
	 * C
	 */
	calendar(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />`+
			`</svg>`
		)
	};

	close(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />`+
			`</svg>`
		)
	};

	clock(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />`+
			`</svg>`
		)
	};
	/**
	 * D
	 */
	delete(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />`+
			`</svg>`
		)
	};
	/**
	 * P
	 */
	phone(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M20,15.5C18.8,15.5 17.5,15.3 16.4,14.9C16.3,14.9 16.2,14.9 16.1,14.9C15.8,14.9 15.6,15 15.4,15.2L13.2,17.4C10.4,15.9 8,13.6 6.6,10.8L8.8,8.6C9.1,8.3 9.2,7.9 9,7.6C8.7,6.5 8.5,5.2 8.5,4C8.5,3.5 8,3 7.5,3H4C3.5,3 3,3.5 3,4C3,13.4 10.6,21 20,21C20.5,21 21,20.5 21,20V16.5C21,16 20.5,15.5 20,15.5M5,5H6.5C6.6,5.9 6.8,6.8 7,7.6L5.8,8.8C5.4,7.6 5.1,6.3 5,5M19,19C17.7,18.9 16.4,18.6 15.2,18.2L16.4,17C17.2,17.2 18.1,17.4 19,17.4V19Z" />`+
			`</svg>`
		)
	};
	/**
	 * S
	 */	
	send(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />`+
			`</svg>`
		)
	};	
	/**
	 * E
	 */	
	default(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="" />`+
			`</svg>`
		)
	};
	/**
	 * F
	 */

	/**
	 * M
	 */
	minimize(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M20,14H4V10H20" />`+
			`</svg>`
		)
	};
	maximize(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="M4,4H20V20H4V4M6,8V18H18V8H6Z" />`+
			`</svg>`
		)
	};
	//---------------------------------------------------------------------------------------------------
	default(color = '#fff', h = 24, w = 24){
		return(
			`<svg style="width: ${w}px; height: ${h}px;" viewBox="0 0 24 24">`+
			`<path fill="${color}" d="" />`+
			`</svg>`
		)
	};




};
export default new Icons();