import Observer from './observer.js';
import Gmap from './gmap.js';
/**
 * Input element or contenteditable input must have class 'autocomplete' and data-list="list name"
 * Element example: <input type="text" class="autocomplete" data-list="clients"> or <div class="autocomplete" data-list="clients" contenteditable="true"></div>
 * Set parent form attr autocomplete="off"
 */
let Singleton = (function(){

	let instance = undefined;

	class Default{

		constructor(){
			let self = this;
			this.defaultList = {};
			/**
			 * ADD LIST STYLE
			 */
			$('body').append(
				`<style>`+
				`.autocomplete-list{position: fixed; z-index: 109;}`+
				`.autocomplete-list-item{background-color: #eee; border-bottom: 1px solid #ccc; text-align: left; font-size: 0.9em; padding: 0 8px; height: 32px; width: 100%;}`+
				`.autocomplete-list-item:focus{background-color: #ccc;}`+
				`</style>`
			);

			this.input = undefined;
			this.input_tag = undefined;
			this.input_top = undefined;
			this.input_left = undefined;
			this.input_width = undefined;
			/**
			 * AUTOCOMPLETE INPUT ONFOCUS 
			 */
			$(document).on('focus', 'input.autocomplete, textarea.autocomplete', function(){
				self.input = $(this);
				self.input_tag = $(this).attr('tagName');	
				self.input_top = $(this).outerHeight() + $(this).offset().top;
				self.input_left = $(this).offset().left;
				self.input_width = $(this).outerWidth(true);
			});
			/**
			 * GET AUTOCOMPLETE LIST ON INPUT TEXT
			 */
			$(document).on('input', 'input.autocomplete, textarea.autocomplete', function(){
				let str = $(this).val().trim();
				if(str.length > 0){
					if($(this).hasClass('google')){
						Gmap.autocomplete(str).then(resolve => {
							self.createList(resolve)
						})
					}else{
						self.getList($(this).data('list'), str);
					}
				}
			});
			/**
			 * SET FOCUS ONHOVER ON AUTOCOMPLETE LIST ITEM
			 */
			$(document).on('mouseenter', '.autocomplete-list-item', function(){
				$(this).focus()
			});
			/**
			 * MOVE CURSOR AND SET FOCUS ON FIRST AUTOCOMPLETE LIST ITEM
			 */
			$(document).on('keydown', 'input.autocomplete, textarea.autocomplete', function(e){
				if((e.keyCode === 38 || e.which === 38) || (e.keyCode === 40 || e.which === 40)) $('.autocomplete-list-item').eq(0).focus();
			});
			/**
			 * MOVE CURSOR UP/DOWN AND SET FOCUS ON AUTOCOMPLETE LIST ITEM
			 */
			$(document).on('keydown', '.autocomplete-list-item:focus', function(e){
				let length = $('.autocomplete-list-item').length - 1;
				let index = $(this).index();
				switch(e.keyCode || e.which){
					case 38: 
					if(index === 0){index = length}else{index--}; 
					break;
					case 40:
					if(index === length){index = 0}else{index++};
					break;
					case 13: 
					self.input.val($(this).text());
					self.input.attr('data-id', $(this).data('id'));
					self.input.focus();
					$('.autocomplete-list').remove();
					Observer.emitEvent({
						'event': $(this).prop('data-event'),
						'target': self.input
					});
					break;
				};
				$('.autocomplete-list-item').eq(index).focus();
			});
			/**
			 * REMOVE AUTOCOMPLETE LIST
			 */
			$(document).on('mouseleave', '.autocomplete-list', () => $('.autocomplete-list').remove());

			$(document).on('mouseleave', 'input.autocomplete, textarea.autocomplete', () => {
				setTimeout(() => {
					if($('.autocomplete-list-item:focus').length == 0) $('.autocomplete-list').remove()
				});
			});
			/**
			 * SELECT OPTION
			 */
			$(document).on('mousedown touchstart', '.autocomplete-list-item', function(e){
				self.input.val($(this).text());
				self.input.attr('data-id', $(this).attr('data-id'));
				self.input.focus();
				$('.autocomplete-list').remove();
				Observer.emitEvent({
					'event': $(this).attr('data-event'),
					'target': self.input
				})
			});
			/**
			 * PREVENT BROWSER AUTOCOMPLETE
			 */
			$(document).on('mouseenter', 'input[autocomplete="off"]', function(){
				$(this).attr('data-temp', $(this).attr('name'));
				$(this).removeAttr('name');
			});

			$(document).on('mouseleave', 'input[autocomplete="off"]', function(e){
				$(this).attr('name', $(this).attr('data-temp'));
			});			

			$(document).on('focus', 'input[autocomplete="off"]', function(e){
				$(this).removeAttr('readonly');
			});
			/**
			 * GET APP EVENTS AND REDIRECT TO METHODS
			 */
			function Subscriber() {
				this.notify = obj => {
					switch (obj.event) {
						case 'autocomplete-list-data': self.createList(obj.data); break;
						case 'google-autocomplete-list': self.createList(obj.data); break;
					}
				}
			};
			/**
			 * SUBSCRIBE TO EVENTS
			 */
			Observer.addSubscriber(new Subscriber());
		};
		/**
		 * show created ITEMs list
		 */
		showList(items){
			$('.autocomplete-list').remove();
			if(items.length === 0) return;
			$('body').append(`<div class="autocomplete-list" style="top: ${this.input_top}px; left: ${this.input_left}px; width: ${this.input_width}px;">${items}</div>`);
		};
		/**
		 * CREATE DYNAMIC DATA ITEMS LIST
		 */
		createList(array){
			if(!array || array.length == 0) return;			
			let items = ``;
			array.forEach(v => items += `<button class="autocomplete-list-item ellipsis" data-id="${v.id}" data-event="autocomplete-option-select">${v.title}</button>`);
			this.showList(items);
		};
		/**
		 * GET AUTOCOMPLETE LIST
		 */
		getList(list, word){
			$.ajax({
				type: 'GET',
				url: '/get/autocomplete-list',
				data: {'list': list, 'word': word}
			}).done((response, status, xhr) => {
				if(response){
					Observer.emitEvent({
						'event': 'autocomplete-list-data',
						'data': JSON.parse(response)
					})
				}
			})
		};

	};

	return {
		getInstance: function(){
			if(!instance) instance = new Default();
			return instance;
		}
	}
})();
export default Singleton.getInstance();