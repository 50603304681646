export default new class{
	constructor(){};
	/**
	 * SET COOKIES
	 */
	set(cookie_name, cookie_value, days){
		let expires = 0;
		if(days > 0){
			let d = new Date();
			d.setTime(d.getTime() + (days*24*60*60*1000));
			expires = d.toUTCString();
		};
		document.cookie = cookie_name +"="+ cookie_value +";expires="+ expires +";path=/;";
	};
	/**
	 * GET COOKIES
	 */
	get(cookie_name) {
		let name = cookie_name + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return false;
	};
	/**
	 * REMOVE COOKIE
	 */
	remove(cookie_name){
		document.cookie = cookie_name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	};
};