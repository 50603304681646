import Observer from './../../../utils/observer.js';
import Driver from './../../../utils/driver.js';
import Gmap from './../../../utils/gmap.js';
import Model from './model.js';
import View from './view.js';

function Controller(){

	let map_created = false;

	function showPanel(){
		// let panel_id = 'panel_' + new Date().getTime();
		// View.createPanel(panel_id);
		if(!map_created) {
			Gmap.createMap('panel-map', 53.236224, -6.625035);	
			map_created = true;
		};
		Driver.showPanel('contact-panel');
	};
	/**
	 * GET APP EVENTS AND REDIRECT TO METHODS
	 */
	function Subscriber() {
		this.notify = obj => {
			switch (obj.event) {
				case 'show-contacts-panel': showPanel(); break;
			}
		}
	};
	/**
	 * SUBSCRIBE TO EVENTS
	 */
	Observer.addSubscriber(new Subscriber());

};
export default new Controller();