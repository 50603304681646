import Observer from './observer.js';
import Autocomplete from './autocomplete.js';

let Gmap = function(){

	let self = this;
	let gmap_id = undefined;
	let map = undefined;
	let markers = [];
	let directionsDisplay;
	let directionsService;
	let autocompleteService = undefined;
	let getMarker = color => {
		return (
			`<svg style="width:32px;height:32px" viewBox="0 0 24 24">`+
		    `<path fill="${color}" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />`+
			`</svg>`)
	};
	/**
	 * CALCULATE DISTANCE BETWEEN COORDINATES
	 */
	this.getDistance = function(coords, callback){
		let startCoords = coords.shift();
		let stopCoords = coords.pop();
		let service = new google.maps.DistanceMatrixService();
		service.getDistanceMatrix({
			'origins': [startCoords],
			'destinations': [stopCoords],
			'travelMode': 'DRIVING',
			// transitOptions: TransitOptions,
			// drivingOptions: DrivingOptions,
			// unitSystem: UnitSystem,
			'avoidHighways': false,
			'avoidTolls': false,
		}, (response, status) => {
		  	let distance = response.rows[0].elements[0].distance.text;
		  	let duration = response.rows[0].elements[0].duration.text;
		  	if(callback) callback(distance, duration);
		});
	};
	/**
	 * DRAW ROUTE FROM COORDINATES
	 * coords = [{lat: 123, lng: 321}, {lat: 123, lng: 321}, {lat: 123, lng: 321} ... ]
	 */
	this.drawRoute = function(coords){
		let startCoordsObj = coords.shift();
		let stopCoordsObj = coords.pop();
		let startCoords = new google.maps.LatLng(parseFloat(startCoordsObj.lat), parseFloat(startCoordsObj.lng));
		let stopCoords = new google.maps.LatLng(parseFloat(stopCoordsObj.lat), parseFloat(stopCoordsObj.lng));
		let waypoints = [];
		coords.forEach((obj) => {
			waypoints.push({
			    location: new google.maps.LatLng(obj.lat, obj.lng),
			    stopover: true
			})
		});		
   		directionsService = new google.maps.DirectionsService();
    	directionsDisplay = new google.maps.DirectionsRenderer();
    	directionsDisplay.setMap(map);
    	directionsDisplay = new google.maps.DirectionsRenderer({ 
    		polylineOptions: { 
    			strokeColor: "#0000ff",
             	strokeOpacity: 0.75,
             	strokeWeight: 4
    		} 
    	});
		let request = {
			origin: startCoords,
			destination: stopCoords,
			waypoints: waypoints,
			avoidHighways: true,
			avoidTolls: true,
			travelMode: google.maps.TravelMode.DRIVING
		};
		directionsService.route(request, function(response, status) {
			if (status == google.maps.DirectionsStatus.OK) {
				directionsDisplay.setDirections(response);
				directionsDisplay.setMap(map);
			} else {
				console.log("Directions Request failed: " + status);
			}
		});
	};
	/**
	 * GET GEOLOCATION
	 */
	this.getGeolocation = function(){
		return new Promise(function(resolve, reject){
		    if(navigator.geolocation){
		        navigator.geolocation.getCurrentPosition((position, error) => {
		            let lat = parseFloat(position.coords.latitude.toFixed(5));
		            let lng = parseFloat(position.coords.longitude.toFixed(5));
		            let coords = {'lat':lat, 'lng':lng}; 
		            resolve(coords);
		            reject(error);
		        });
		    };		
		});
	};
	/**
	 * GET ADDRESS BY COORDINATES
	 */
	this.getAddressByCoords = function(coords){
		return new Promise(function(resolve, reject){
			let geocoder = new google.maps.Geocoder;
			geocoder.geocode({'location': coords}, function(results, status) {
				if (status === 'OK') {
					if (results[1]) {
						resolve(results[1]);
					} else {
						reject(status);
					}
				} else {
					reject('Error geolocation address'); //'Geocoder failed due to: ' + status;
				}
			});
		});
	};
	/**
	 * GET COORDINATES BY ADDRESS
	 */
	this.getCoordsByAddress = function(address){
		return new Promise((resolve, reject) => {
			let geocoder = new google.maps.Geocoder;
			geocoder.geocode({'address': address}, function(results, status) {
				if (status === 'OK') {
	           		resolve({
	           			'lat': results[0].geometry.location.lat().toFixed(6), 
	           			'lng': results[0].geometry.location.lng().toFixed(6)
	           		});
				} else {
					reject(status);
				}
			});
		});
	};
	/**
	 * GET PLACE COORDINATES BY GOOGLE PLACE ID
	 */
	this.getCoordsByPlaceId = (place_id) => {
		return new Promise(function(resolve, reject){
			let geocoder = new google.maps.Geocoder;
			geocoder.geocode({'placeId': place_id}, function(results, status) {
				if (status !== 'OK') {
	            	reject('Geocoder: Coords by PlaceId failed due to: ' + status);
	            } else {
	           		resolve({
	           			'lat': results[0].geometry.location.lat().toFixed(6), 
	           			'lng': results[0].geometry.location.lng().toFixed(6)
	           		});
	            }
			})
		});
	};
	/**
	 * REMOVE ALL MARKERS FROM MAP
	 */
	this.removeMarkers = () => {
		if(markers.length == 0) return;
		for (var i = 0; i < markers.length; i++) {
			markers[i].setMap(null);
		};
		markers = [];
	}
	/**
	 * ADD MARKERS AND RELOCATE MAP/CHANGE VIEW
	 * markers = [{lat,lng,info,id},{},......]
	 */
	this.addMarkers = function(list){
		self.removeMarkers();
		if(list.length == 0) return;
		let red_pin = getMarker('#ff3300');
		let pin_home = "/images/icons/map/home.svg";
		let pin_user = "/images/icons/map/user.svg";
		let bounds  = new google.maps.LatLngBounds();
		list.forEach((v) => {
			let icon_url = pin_home;
			let popup = new google.maps.InfoWindow({
				content: `<div class="marker-info" style="font-size: 0.9em;">${v.info}</div>`
			});
			let pos = new google.maps.LatLng(v.lat, v.lng);
			bounds.extend(pos);
			let marker = new google.maps.Marker({
				position: pos,
				map: map,
				icon: {
			      url: icon_url
			    },
			    customInfo: {id: v.id}
			});
			markers.push(marker);
			marker.addListener('click', function() {
				popup.open(map, marker);
				//console.log(this.customInfo.id);
			});
		});
		map.fitBounds(bounds);// autozoom
		map.panToBounds(bounds); //autocenter
		map.setZoom(9);
	};
	/**
	 * CHANGE MAP CENTER COORDINATES
	 */
	this.centerMap = function({lat, lng}){
	    var center = new google.maps.LatLng(lat, lng);
    	let marker = new google.maps.Marker({
			position: center,
			map: map
		});
	    map.panTo(center);
	    document.getElementById(gmap_id).style.opacity = 0;
	    map.setZoom(11);
	    setTimeout(() => document.getElementById(gmap_id).style.opacity = 1, 800);
	};
	/**
	 * CREATE MAP
	 */
	this.createMap = function(map_id, lat, lng){
		try{
			gmap_id = map_id;
			if(map) return;
			let center = new google.maps.LatLng(lat, lng);
			let map_center =  new google.maps.LatLng(lat, lng);
	        map = new google.maps.Map(document.getElementById(map_id),{
	            center: map_center,
	            zoom: 14,
	            mapTypeId: google.maps.MapTypeId.ROADMAP
	        });
	    	let marker = new google.maps.Marker({
				position: center,
				map: map
			});
		    map.panTo(center);
		}catch(error){
			console.log(error)
		}
	};	
	/**
	 * ADDRESS AUTOCOMPLETE
	 */
	this.autocomplete = function(str){
		if(str.length == 0) return;
		if(!autocompleteService) autocompleteService = new google.maps.places.AutocompleteService();
		let promise = new Promise((resolve, reject) =>{
			autocompleteService.getPlacePredictions({
				'input': str.trim(),
				'componentRestrictions':{'country': 'ie'},
				'types': ['address']
			}, function(predictions, status){
				if(predictions.length == 0) return;
				let temp = []; 
				if (status = google.maps.places.PlacesServiceStatus.OK) {
					predictions.forEach(v => temp.push({'id': v.place_id, 'title': v.description}));
					if(temp.length > 0) resolve(temp);
				}else{
					reject(status);
				}
			});				
		});
		return promise;
	};

};
export default new Gmap();