let Singleton = (function(){

	let instance = undefined;

	class DateTime{

		constructor(_separator = '/'){
			this.separator = _separator;
			this.dayNames = {
				'en': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
				'ro': ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'],
				'ru': ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
			};
			this.monthNames = {
				'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				'ro': ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
				'ru': ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
			};
		};

		getHm(ts = new Date().getTime()){ 
			if(ts.toString().length == 10) ts = ts * 1000; 
			let x = new Date(parseInt(ts));
			return x.getHours() +':'+ ('0' + x.getMinutes()).slice(-2);
		};

		getDmy(ts = new Date().getTime()){
			if(ts.toString().length == 10) ts = ts * 1000; 
			let x = new Date(parseInt(ts));
			let d = x.getDate();
			let m = x.getMonth() + 1;
			let y = x.getFullYear()
			return ('0' + d).slice(-2) + this.separator + ('0' + m).slice(-2) + this.separator + y;
		};

		getDmyLong(ts = new Date().getTime()){
			if(ts.toString().length == 10) ts = ts * 1000; 
			let x = new Date(parseInt(ts));
			let d = x.getDate();
			let m = x.getMonth();
			let y = x.getFullYear();
			return d + ' ' + this.monthNames['en'][m] + ' ' + y;
		};

		getDmyHm(ts = new Date().getTime()){ 
			if(ts.toString().length == 10) ts = ts * 1000; 
			let x = new Date(parseInt(ts));
			let d = x.getDate();
			let m = x.getMonth() + 1;
			let y = x.getFullYear();
			return ('0' + d).slice(-2) + this.separator + ('0' + m).slice(-2) + this.separator + y.toString() + '  ' + x.getHours() +':'+ ('0' + x.getMinutes()).slice(-2);
		};

		getTs(year, month, date, hours = 0, minutes = 0){ 
			 return new Date(year, month, date, hours, minutes).getTime();
		};
	};

	return {
		getInstance: function(){
			if(!instance) instance = new DateTime();
			return instance;
		}
	};

})();
export default Singleton.getInstance();