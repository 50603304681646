import Observer from './../../../utils/observer.js';
import Driver from './../../../utils/driver.js';
import Ajax from './../../../utils/ajax.js';
import View from './view.js';

function Controller(){
	/**
	 * CREATE AND SHOW SIGNIN POPUP PANEL
	 */
	function showPanel(){
		if(Cookies.get('uid')){
			window.open('https://landmarkenvironmental.ie/cpanel/admin', '_self');
		}else{
			let panel_id = 'panel_' + new Date().getTime();
			View.createPanel(panel_id);
			Driver.showPanel(panel_id);
		}
	};
	/**
	 * SIGNIN
	 * redirect_url = '/cpanel/admin'; 
	 * redirect_url = '/cpanel/engineer';
	 * redirect_url = '/cpanel/customer';
	 */
	function signin(){
		let email = $('#user-email').val().trim();
		let password = $('#user-password').val().trim();
		if(email.length == 0 || password.length == 0){
			alert('Please enter login and password!');
			return;
		};
		if(ValidateEmail(email) == false){
			alert('Please enter a valid email address!');
			return;			
		};
		Ajax.getData({
			'url': '/authorize/signin', 
			'params': {'email': email, 'password': password}, 
			callback: function(result){
				let json = JSON.parse(result);
				if(json.status == 200) {
					Cookies.set('uid', json.data.uid, 30);
					window.open(json.data.page_url, '_self');
				}else{
					alert('ERROR: Please verify login, password and role')
				}
			}
		})
		
	};
	/**
	 * ON FORGOT PASSWORD
	 */
	function onForgotPassword(obj){
		let email = $('#user-email').val().trim();			
		if(email.length == 0){
			alert('Please enter email address!');
			$('#user-email').focus();
			return;
		};
		if(!ValidateEmail(email)){
			alert('Please enter a valid email address!');
			$('#user-email').focus();
			return;			
		};
		$('*').css('cursor', 'wait');
		Ajax.postData({
			'url': '/authorize/email-authorize-link', 
			'params': {'email': email}, 
			callback: function(result){
				$('*').css('cursor', 'default');
				let json = JSON.parse(result);
				if(json.status == 200) {
					setTimeout(() => alert('Authorization link sent to email address!'),60);
				}else{
					setTimeout(() => alert('ERROR: Account not exists!'),60);
					console.log(result);
				}
			}
		})		
	};
	/**
	 * GET APP EVENTS AND REDIRECT TO METHODS
	 */
	function Subscriber() {
		this.notify = obj => {
			switch (obj.event) {
				case 'show-authorize-panel': showPanel(); break;
				case 'user-signin': signin(); break;
				case 'forgot-password': onForgotPassword(obj); break;
			}
		}
	};
	/**
	 * SUBSCRIBE TO EVENTS
	 */
	Observer.addSubscriber(new Subscriber());

};
export default new Controller();