function View(){
	
	this.createPanel = (panel_id) => {
		$('body').append(
			`<div id="${panel_id}" class="panel popup" style="width: 320px;">`+
			`<table class="h100 w100">`+
			`<tbody>`+
			`<tr>`+
			`<td>`+
			`<div class="panel-header" style="height: 40px;">`+
			`<span class="panel-title">SIGN IN</span>`+
			`<button class="ic-close-w h100" data-event="hide-panel" style="width: 40px;"></button>`+
			`</div>`+
			`</td>`+
			`</tr>`+
			`<tr>`+
			`<td class="h100 wrapper">`+
			`<div class="pad-8">`+
			`<input type="text" autocomplete="new-password" placeholder="Enter email address" id="user-email">`+
			`</div>`+
			`<div class="pad-8">`+
			`<input type="password" autocomplete="new-password" placeholder="Enter password" id="user-password">`+
			`</div>`+
			`<div class="pad-8">`+
			`<button class="custom bg-green" data-event="user-signin">SignIn</button>`+
			`</div>`+
			`<div class="pad-8">`+
			`<button class="custom" data-event="forgot-password">Send authorization link to email</button>`+
			`</div>`+
			`</td>`+
			`</tr>`+
			`<tr>`+
			`<td>`+
			`</td>`+
			`</tr>`+
			`</tbody>`+
			`</table>`+
			`</div>`
		);
	}

};
export default new View();