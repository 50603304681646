function Slider(){

	if($('#slider').length == 0) return;

	$('#slider').append(`<div id="slide-links"></div>`);

	let i = 0;
	let titles = [];
	$('.slide-title').each(function(){
		titles.push($(this).val());
	});
	let list = [];
	$('.slide-path').each(function(){
		list.push($(this).attr('src'));
	});
	let max = list.length - 1;
	for(let j = 0; j < list.length; j++){
		$('#slide-links').append('<button class="slide-link"></button>');
	};
	$('.slide-link').first().addClass('active');

	let slider = $('#slider');
	let slides = slider.find('#slides');

	let nextSlide = function(){
		$('#slider-text-overlay').fadeOut();
		let w = slider.width();
		if(i < max){ i++ } else { i = 0 };
		slides.append(`<div class="slide"><img src="${list[i]}" alt="slide"></div>`);
		$('.slide-link.active').removeClass('active');
		setTimeout(() => {
			slides.css({'transition':'1s', 'transform':'translate3d(-' + w + 'px,0,0)', '-webkit-transform':'translate3d(-' + w + 'px,0,0)'});
			setTimeout(() => {
				$('.slide').first().remove();
				slides.css({'transition':'0s', 'transform': 'translate3d(0,0,0)', '-webkit-transform': 'translate3d(0,0,0)'});
				$('.slide-link').eq(i).addClass('active');
				$('#slider-text-overlay').html(titles[i]).fadeIn();
			}, 900);
		},60);
	};

	let prevSlide = function(){
		$('#slider-text-overlay').fadeOut();
		let w = slider.width();
		if(i > 0){ i-- } else { i = max};
		slides.css({'transition':'0s', 'transform':'translate3d(-' + w + 'px,0,0)', '-webkit-transform':'translate3d(-' + w + 'px,0,0)'});
		slides.prepend(`<div class="slide"><img src="${list[i]}" alt="slide"></div>`);
		$('.slide-link.active').removeClass('active');
		setTimeout(() => {
			slides.css({'transition':'1s', 'transform': 'translate3d(0,0,0)', '-webkit-transform': 'translate3d(0,0,0)'});
			setTimeout(() => {
				$('.slide').last().remove();
				$('.slide-link').eq(i).addClass('active');
				$('#slider-text-overlay').html(titles[i]).fadeIn();
			}, 900);
		}, 100);
	};

	let timer = null;

	let startSlider = function(){
		timer = setInterval(() => {
			nextSlide();
		}, 4000);
	};

	$(document).on('click', 'button#btn-next-slide', () => nextSlide());

	$(document).on('click', 'button#btn-prev-slide', () => prevSlide());

	$(document).on('click', 'button.slide-link', function(){
		let index = $(this).index();
		i = index;
		$('#slider-text-overlay').html(titles[i]);
		$('.slide').html(`<img src="${list[i]}" alt="slide">`);
		$('.slide-link.active').removeClass('active');
		$('.slide-link').eq(i).addClass('active');
	});

	$(document).on('mouseenter', '#btn-next-slide, #btn-prev-slide', function(){
		clearInterval(timer);
	}).on('mouseleave', '#btn-next-slide, #btn-prev-slide', function(){
		startSlider();
	});

	startSlider();

};
export default new Slider();