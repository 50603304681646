function Polyslider(){

	if($('.polyslider').length == 0) return;
	

	let next = function(){
		let w = - $('.polyslide-wrapper').first().outerWidth();
		$('.polyslider-container').append($('.polyslide-wrapper').first().clone());
		$('.polyslider-container').css({
			'transform':'translate3d('+ w +'px,0,0)', 
			'-webkit-transform':'translate3d('+ w +'px,0,0)', 
			'transition': '0.5s',
			'-webkit-transition': '0.5s',
		});
		setTimeout(() => {
			$('.polyslide-wrapper').first().remove();
			$('.polyslider-container').css({
				'transform': 'translate3d(0,0,0)', 
				'-webkit-transform': 'translate3d(0,0,0)', 
				'transition': '0s',
				'-webkit-transition': '0s',
			})
		}, 550)
	};

	let prev = function(){
		let w = - $('.polyslide-wrapper').last().outerWidth();
		$('.polyslider-container').prepend($('.polyslide-wrapper').last().clone());
		$('.polyslider-container').css({
			'transform':'translate3d('+ w +'px,0,0)', 
			'-webkit-transform':'translate3d('+ w +'px,0,0)', 
			'transition': '0s',
			'-webkit-transition': '0s',
		});
		setTimeout(() => {
			$('.polyslide-wrapper').last().remove();
			$('.polyslider-container').css({
				'transform': 'translate3d(0,0,0)', 
				'-webkit-transform': 'translate3d(0,0,0)', 
				'transition': '0.5s',
				'-webkit-transition': '0.5s',
			})
		}, 550)
	};

	let timer = null;

	let start = function(){
		timer = setInterval(() => {
			next();
		}, 2100);
	};

	$(document).on('click', 'button.show-next', () => next());

	$(document).on('click', 'button.show-prev', () => prev());

	$(document).on('mouseenter', 'button.show-next, button.show-prev', function(){
		clearInterval(timer);
	}).on('mouseleave', 'button.show-next, button.show-prev', function(){
		start();
	});

	start();
};
export default new Polyslider();