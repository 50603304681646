let Singleton = (function(){

	let instance = undefined;
	let progress_bar_id = undefined;

	class Ajax{

		constructor(){};

		postForm({data, url, callback}){
			$.ajax({
				'type': 'post',
				'url': url, 
				'data': data,
				'processData': false,
				'contentType': false,
				//dataType: 'json',
				xhr: () => {
					let xhr = $.ajaxSettings.xhr(); 
					xhr.upload.addEventListener('progress', (e) => {
						if (progress_bar_id && e.lengthComputable){
							let level = e.loaded || e.position;
							let percent = Math.ceil( level / e.total * 100);
							document.getElementById(progress_bar_id).style.width = percent + '%';
						}
					}, false);
					return xhr;
				}, success: (response, text) => {
					if( callback && typeof(callback) === 'function' ) callback(response);
				}, error: (request, status, error) => {
					console.error(request.responseText);
				}
			})
		};

		getData({url, params, callback}){
			$.ajax({
				type: 'GET',
				url: url,
				data: params
			}).done((response, status, xhr) => {
				if(callback && typeof(callback) === 'function') callback(response);
			})
		};

		postData({url, params, callback}){
			$.ajax({
				type: 'POST',
				url: url,
				data: params
			}).done((response, status, xhr) => {
				if(callback && typeof(callback) === 'function') callback(response);
			}).fail(function(error){
				console.error(error);
			});				
		};

		putData({url, params, callback}){
			$.ajax({
				type: 'DELETE',
				url: url,
				data: params
			}).done((response, status, xhr) => {
				if(callback && typeof(callback) === 'function') callback(response);
			}).fail(function(error){
				console.error(error);
			});				
		};

		deleteData({url, params, callback}){
			$.ajax({
				type: 'DELETE',
				url: url,
				data: params
			}).done((response, status, xhr) => {
				if(callback && typeof(callback) === 'function') callback(response);
			}).fail(function(error){
				console.error(error);
			});				
		};

		getAsyncData({url, params}){
			return new Promise((resolve, reject) => {
				$.ajax({
					type: 'GET',
					url: url,
					data: params
				}).done((response, status, xhr) => {
					resolve(response);
				}).fail(function(error){
					reject(error);
				});	
			})			
		}

		postAsyncData({url, params}){
			return new Promise((resolve, reject) => {
				$.ajax({
					type: 'POST',
					url: url,
					data: params
				}).done((response, status, xhr) => {
					resolve(response);
				}).fail(function(error){
					reject(error);
				});				
			})
		}
	};

	return {
		getInstance: function(){
			if(!instance) instance = new Ajax();
			return instance;
		}
	}

})();
export default Singleton.getInstance();