function View(){
	
	this.createPanel = (panel_id) => {
		$('body').append(
			`<div id="${panel_id}" class="panel slide right">`+
			`<table class="h100 w100">`+
			`<tbody>`+
			`<tr>`+
			`<td>`+
			`<div class="panel-header">`+
			`<button class="h100" data-event="hide-panel" style="width: 40px;">${Icons.close('#fff', 24,24)}</button>`+
			`</div>`+
			`</td>`+
			`</tr>`+
			`<tr>`+
			`<td style="padding: 16px; line-height: 1.4em; vertical-align: top;">`+
			`<b>ADDRESS</b><br>`+
			`Landmark Environmental Services Ltd.<br>`+
			`Johnstown Business Centre<br>`+
			`Johnstown Naas Co., Kildare, Ireland<br>`+
			`Phone: 045 254221 / 086 8228300`+
			`</td>`+
			`</tr>`+
			`<tr><td class="h100" id="panel-map"></td></tr>`+
			`</tbody>`+
			`</table>`+
			`</div>`
		);
	}

};
export default new View();